<template>
  <div v-if="loading" class="loading-container">
    <van-loading size="24px" vertical>借款中...</van-loading>
  </div>
  <div v-else class="countdown-container">
      <!-- 打雷图标 -->
    <div v-if="countdown > 0" class="countdown-animation">
    <img src="../assets/money1.png" class="lightning-icon" alt="Lightning"/>
    <br>
    <div class="circle-animation"></div>
    {{ countdown }}s
  </div>
    <div v-else>
      <!-- 倒计时结束后的内容，可以是重定向或者显示额度信息 -->
      <!-- 举例显示额度信息 -->
      <div class="result-container">
        <div class="amount-circle">提款失败  </div>
        <div class="rate">抱歉，经综合评估您暂时无法提款!无法提款原因可能是因为:1.名下账户出现逾期、止付、呆账情况2.名下账户存在多头授信3.疑似关注股市楼市，资金流动异常，以贷养贷等情况4.综合评分不足，请提交第三方受托账户支付收款
        </div>
        <br>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';


const loading = ref(true);
const countdown = ref(3);

setTimeout(() => {
  loading.value = false;
  const intervalId = setInterval(() => {
    countdown.value--;
    if (countdown.value === 0) {
      clearInterval(intervalId);
    }
  }, 1000);

}, 2000); // 假设加载需要1秒


</script>

<style scoped>
.loading-container,
.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 全屏高度 */
}

.result-container {
  text-align: center;
}

.amount-circle {
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin: 20px auto;
  font-size: 24px;
}

.congratulations, .rate {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left !important;
  word-break: break-all;
}

.lightning-icon {
  width: 100px; /* 调整图标的宽度 */
  height: 100px; /* 调整图标的高度 */
}

.rate{
  font-size: 12px;
  color: gray;
}

.loan-button {
  margin-top: 20px;
}
/* 现有样式保持不变，添加以下新样式 */

.countdown-animation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px; /* 调整倒计时数字的大小 */
}

.circle-animation {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 25px solid rgba(243, 214, 221, 0.5);
  border-bottom-color: rgb(236, 77, 77); /* 半圈的颜色 */
  box-shadow: 0 0 20px rgb(226, 172, 172); /* 光晕效果 */
  animation: spin 1s linear infinite;
}

.loading-text {
  /* position: absolute; */
  top: -250px; /* 根据需要调整，以确保文字显示在圆圈上方 */
  font-size: 20px; /* 调整文字大小 */
  color: hsl(0, 91%, 44%); /* 文字颜色，可以根据您的设计进行调整 */
  font-weight: bold; /* 加粗字体 */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

import { createApp } from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css'; // 引入Vant样式
import router from './router';
import axios from './axios';


const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(router);
app.use(Vant);

app.mount('#app');

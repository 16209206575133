import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api', // 默认URL，可以在环境变量中配置
  timeout: 50000, // 请求超时设置
  headers: {
    'Content-Type': 'application/json',
  },
});

// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response;
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;

import { createRouter, createWebHistory } from 'vue-router';
import LoanApplicationVue from '../components/LoanApplication.vue';
import MainView from '../components/MainView.vue';

const routes = [
    {
        path: '/',
        name: 'Index',
        component: MainView,
    },
    {
        path: '/loanApplication',
        name: 'loanApplication',
        component: LoanApplicationVue,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


export default router;

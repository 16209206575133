<template>
<div>
  <div class="loan-application"  v-if="!showWaitRent&&!showLoanDetails">
    <van-nav-bar
      title="借款"
      left-text="返回"
      class="bar"
      left-arrow
      @click-left="$router.go(-1)"
    ></van-nav-bar>
    <div class="content">
    <div class="form-item">
      <label>借款金额</label>
      <van-field
        v-model="loanAmount"
        type="number"
        label="¥"
        placeholder="请输入"
        input-align="left"
        clearable
        :right-icon="clearIcon"
        @change="check"
        @click-right-icon="clearLoanAmount"
      ><template #label>
         <span class="required"> ¥</span>
        </template>
      </van-field>
      <div class="hint">最多可借 <span class="max-amount">￥{{amount}}</span> 元</div>
    </div>
    <div class="form-item">
      <label class="sub-label">还款期数</label>
      <van-radio-group v-model="term" direction="horizontal">
        <van-radio v-for="item in radioValues" :key="item.name" :name="item.name">{{ item.text }}</van-radio>
      </van-radio-group>
    </div>
    <!-- 还款方式 -->
    <div class="form-item-inline" @click="showRepaymentMethodPopup1">
      <label class="sub-label">还款方式</label>
      <div class="sub-label1">{{ repaymentMethod }}<van-icon name="arrow" /></div>
    </div>
    <van-popup v-model:show="showRepaymentPopup" position="bottom">
      <van-picker
        title="选择还款方式"
        :columns="repaymentMethods"
        @confirm="onRepaymentMethodConfirm"
        @cancel="showRepaymentPopup = false"
      />
    </van-popup>

    <!-- 还款详情 -->
    <div class="form-item-inline">
      <label class="sub-label">还款详情</label>
      <van-button type="default" class="search" @click="showRepaymentDetails">查看</van-button>
    </div>

    <!-- 贷款用途 -->
    <div class="form-item-inline" @click="showLoanPurposePopup1">
      <label class="sub-label">贷款用途</label>
      <div class="sub-label1">{{ loanPurpose }}<van-icon name="arrow" /></div>
    </div>
    <van-popup v-model:show="showLoanPurposePopup" position="bottom">
      <van-picker
        title="选择贷款用途"
        :columns="loanPurposes"
        @cancel="showLoanPurposePopup = false"
        @confirm="onLoanPurposeConfirm"
      />
    </van-popup>

    <!-- 年利率 -->
    <div class="form-item-inline">
      <label class="sub-label">年利率</label>
      <div class="sub-label1">{{rate}}%</div>
    </div>

    <!-- 收款账户 -->
    <div class="form-item-inline">
      <label class="sub-label">收款账户</label>
      <div class="sub-label1">{{account}}</div>
    </div>

    <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />

    <!-- 下一步按钮 -->
    <van-button type="danger" block round class="submit-button"  @click="onSubmit">
        <van-loading v-if="loading" type="spinner" size="20px" />下一步
        </van-button>


      <van-popup v-model:show="showRepaymentDetailPopup" position="bottom" style="height: 80%;">
    <div class="repayment-detail">
      <div class="summary">
        <p>还款总额: <br><span class="pay">¥{{ repaymentDetails.totalPayment.toFixed(2) }} 元</span></p>
        <br>
        <p>利息总额: <span>¥{{ repaymentDetails.totalInterest.toFixed(2) }} 元</span></p>
      </div>
      <div class="details">
        <div v-for="(item, index) in repaymentDetails.repayments" :key="index" class="repayment-item">
          <p>第 {{ item.period }} 期 - 还款日期: <span class="repayment-detail-date">{{ item.date }}</span></p>
          <p>还款金额: <span>{{ item.repayment.toFixed(2) }} 元</span><span class="money">本金: <span>{{ item.principal.toFixed(2) }} 元</span>, 利息: <span>{{ item.interest.toFixed(2) }} 元</span></span></p>
        </div>
      </div>
    </div>
  </van-popup>
   
  </div>
 </div>
  <div v-if="showWaitRent">
    <WaitRent />
</div>
<div v-if="showLoanDetails" class="loan-details">
<van-nav-bar
      title="详细信息"
      left-text="返回"
      class="bar"
      left-arrow
      @click-left="$router.go(-1)"
    ></van-nav-bar>
<div class="formitem">
    <div class="form-item">
        <label>借款金额:</label>
        <span>{{ loanAmount }}</span>
    </div>
    <div class="form-item">
        <label>还款账号:</label>
        <span>{{ account }}</span>
    </div>
    <div class="form-item">
        <label>还款方式:</label>
        <span>{{ repaymentMethod }}</span>
    </div>
    <div class="form-item">
        <label>年利率:</label>
        <span>{{ rate }}%</span>
    </div>
    <div class="form-item">
        <label>贷款期数:</label>
        <span>{{ term }}个月</span>
    </div>
    <div class="form-item">
        <label>起止时间:</label>
        <span>{{ loanStartDate }} 至 {{ loanEndDate }}</span>
    </div>
    <div class="form-item">
        <label>还款日:</label>
        <span>{{ repaymentDate }}</span>
    </div>
    <div class="form-item">
        <span class="hint">温馨提示:请于次日期之前按时还款，逾期将影响您的个人征信记录。</span>
    </div>
    <div class="form-item">
        <label>用途:</label>
        <span>{{ loanPurpose }}</span>
    </div>
   </div> 
    <van-button type="danger" block round class="submit-button" @click="confirmLoan">
    <van-loading v-if="loading" type="spinner" size="20px" />确定借款</van-button>
    <CustomAlert v-if="showAlert" :message="alertMessage" :type="alertType" />
</div>
</div>
</template>

<script setup>
import { ref,onMounted } from 'vue';
import WaitRent from './WaitRent.vue';
import { useRoute } from "vue-router";
import axios from "@/axios";
import CustomAlert from "./CustomAlert.vue";


const showAlert = ref(false);
const alertMessage = ref("");
const alertType = ref("");


const route = useRoute();
const amount = ref(route.query.amount);
const userid = ref(route.query.userid);
const rate = ref(route.query.rate);
const account = ref(route.query.account);
const email = ref(route.query.email);
const loanAmount = ref('');
const term = ref('');
const repaymentMethod = ref('请选择');
const repaymentMethods = [{text:'按月付息到期还本',value: 1}, {text:'等额本息', valye: 2}]; // 示例还款方式
const showRepaymentPopup = ref(false);
const loanPurpose = ref('请选择');
const loanPurposes = []; // 示例贷款用途
const showLoanPurposePopup = ref(false);
const clearIcon = 'clear'; // 清除图标

const radioValues = ref([
  { name: "36", text: "36期" },
  { name: "24", text: "24期" },
  { name: "12", text: "12期" }
]);


const loading = ref(false);
const countdown = ref(3);
const showResult = ref(false);
const showWaitRent = ref(false);

// 控制借款详情显示
const showLoanDetails = ref(false);

// 借款起止时间和还款日计算（示例）
const loanStartDate = ref('');
const loanEndDate = ref('');
const repaymentDate = ref('');


const showCustomAlert = (message, type = "info") => {
  alertMessage.value = message;
  alertType.value = type;
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};

const check = () => {
    if(Number(loanAmount.value) > Number(amount.value)){
        showCustomAlert("最多可借"+amount.value,'warning');
        loanAmount.value = 0;
    }
}

const fetchTableData = async () => {
  try {
    const response = await axios.get("/loan-purposes");
    if (response.status == 200) {
      if (response.data != null) {
        for (let i = 0; i < response.data.length; i++) {
          var content = response.data[i].content;
          var data1 = {
            text: content,
            value: response.data[i].importance,
          };
          loanPurposes.push(data1);
        }
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    console.error("获取数据失败:", error);  
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
  }
};

onMounted(fetchTableData);



function clearLoanAmount() {
  loanAmount.value = '';
}

function showRepaymentMethodPopup1() {
  showRepaymentPopup.value = true;
}

async function onRepaymentMethodConfirm(value) {
  repaymentMethod.value = `${value.selectedOptions[0].text}`;
  showRepaymentPopup.value = false;
   try {
    const response = await axios.get(`/repayment_periods-method/${repaymentMethod.value}`);
    if (response.status == 200) {
      if (response.data != null) {
         radioValues.value = [];  
        for (let i = 0; i < response.data.length; i++) {
          var term = response.data[i].term;
          radioValues.value.push({name:term, text:term+"期"});
        }
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    console.error("获取数据失败:", error);  
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
  }
}


function showLoanPurposePopup1() {
  showLoanPurposePopup.value = true;
}

function onLoanPurposeConfirm(value) {
  loanPurpose.value = `${value.selectedOptions[0].text}`;
  showLoanPurposePopup.value = false;
}

function onSubmit() {
    // 输入验证
    if (!loanAmount.value) {
        showCustomAlert("请输入借款金额",'warning');
        return;
    }

    if (!repaymentMethod.value||repaymentMethod.value=="请选择") {
        showCustomAlert("请选择还款方式",'warning');
        return;
    }
  
    if (!term.value) {
        showCustomAlert("请选择贷款期数",'warning');
        return;
    }
    if (!loanPurpose.value||loanPurpose.value=="请选择") {
        showCustomAlert("请选择借款用途",'warning');
        return;
    }

    // 确保借款金额是一个有效的数字
    const amount = parseFloat(loanAmount.value);
    if (isNaN(amount) || amount <= 0) {
        showCustomAlert("借款金额必须是一个大于0的数字",'warning');
        return;
    }

    // 确保年利率是一个有效的数字
    const interestRate = parseFloat(rate.value);
    if (isNaN(interestRate) || interestRate <= 0) {
        showCustomAlert("年利率必须是一个大于0的数字",'warning');
        return;
    }

    // 确保贷款期数是一个有效的数字
    const loanTerm = parseInt(term.value);
    if (isNaN(loanTerm) || loanTerm <= 0) {
        showCustomAlert("贷款期数必须选择",'warning');
        return;
    }

   // 示例：设置借款起止时间和还款日
    const today = new Date();
    loanStartDate.value = today.toLocaleDateString();
    today.setMonth(today.getMonth() + parseInt(term.value));
    loanEndDate.value = today.toLocaleDateString();
    repaymentDate.value = `每个月${today.getDate()}号`;

    // 显示借款详情视图
    showLoanDetails.value = true;
}

// 确认借款函数
const confirmLoan = async() => {
    // 在这里处理借款确认逻辑
    console.log("借款已确认");
  loading.value = true;
  let counter = 3;
  const intervalId = setInterval(() => {
    countdown.value = counter--;
    if (counter < 0) {
      clearInterval(intervalId);
    }
  }, 1000);
  const data = {
        userid: userid.value,
        withdrawal_amount: parseFloat(loanAmount.value),
        repayment_periods: parseInt(term.value),
        repayment_method: repaymentMethod.value,
        purpose:  loanPurpose.value,
        annual_rate: parseFloat(rate.value),
        credit_limit: parseFloat(amount.value),
        email: email.value,
    }
  try {
    const response = await axios.post("/loan-details", data);
    if (response.status == 200) {
        showCustomAlert(response.data.message, "success");
        setTimeout(() => {
          loading.value = false;
          showResult.value = true;
          showWaitRent.value = true;
          showLoanDetails.value = false;
        }, 2000);
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "提交失败", "error");
    console.error(error);
  }
};

//查看的显示
// 在<script setup>中添加
const showRepaymentDetailPopup = ref(false);
const repaymentDetails = ref({});

// 根据选择的还款方式计算还款详情
function calculateRepaymentDetails() {
  const principal = parseFloat(loanAmount.value); // 借款金额转换为元
  const monthlyRate = parseFloat(rate.value) / 12 / 100; // 月利率
  let totalInterest = 0; // 总利息
  let totalPayment = 0; // 总还款
  const repayments = []; // 每期还款详情

  if (repaymentMethod.value === '按月付息到期还本') {
    totalInterest = principal * monthlyRate * term.value;
    totalPayment = principal + totalInterest;
    for (let i = 1; i <= term.value; i++) {
      const interest = principal * monthlyRate;
      const repayment = i === parseInt(term.value) ? principal + interest : interest;
      const repaymentDate = new Date(); // 假设今天是首次还款日期
      repaymentDate.setMonth(repaymentDate.getMonth() + i); // 设置每期的还款日期
      repayments.push({ period: i, repayment, principal: i === parseInt(term.value) ? principal : 0, interest , date: repaymentDate.toLocaleDateString() });
    }
  } else if (repaymentMethod.value === '等额本息') {

    // 等额本息每月还款金额计算公式
    const monthlyRepayment = (principal * monthlyRate * Math.pow(1 + monthlyRate, term.value)) / (Math.pow(1 + monthlyRate, term.value) - 1);


   for (let i = 1; i <= term.value; i++) {
    const principalRepayment = principal/term.value; // 当期本金   
    const interest = monthlyRepayment- principalRepayment; // 当期利息

    totalInterest += interest; // 累加总利息

    // 计算还款日期
    const repaymentDate = new Date();
    repaymentDate.setMonth(repaymentDate.getMonth() + i); // 假设立即开始还款

    repayments.push({
      period: i,
      repayment: monthlyRepayment,
      principal: principalRepayment,
      interest,
      date: repaymentDate.toLocaleDateString() // 格式化还款日期
    });
  }
    totalPayment = principal + totalInterest; // 总还款金额
  }

  repaymentDetails.value = {
    totalPayment,
    totalInterest,
    repayments
  };
}

// 展示还款详情
function showRepaymentDetails() {
  if (!loanAmount.value || !term.value || repaymentMethod.value === '请选择') {
    showCustomAlert("请先选择借款金额、填写还款期数和选择还款方式", "error");
    return;
  }
  calculateRepaymentDetails();
  showRepaymentDetailPopup.value = true;
}


</script>


<style scoped>
.loan-application {
  padding: 10px;
  background-color: aliceblue;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
}

.content{
   padding: 10px; 
   border: 5px rgb(94, 18, 18);
   border-radius: 20px;
   background-color: white;
  
}
.bar{
   background-color: aliceblue;
}

.form-item {
  margin-bottom: 16px;
}

.form-item-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #ccc;
}

.required{
    font-size: 20px;
    float: right;
}

.form-item-inline label.sub-label {
  color: grey;
  font-size: 14px; /* 调整为需要的大小 */
}

.sub-label1{
  color: grey;
  font-size: 14px;
  float: right;
}

.hint, .form-item div {
  margin-top: 8px;
  font-size: 12px;
  color: gray;
}

.max-amount {
  color: red;
}

.submit-button {
  margin-top: 32px;
}

.search{
    border: none;
    color:hsl(0, 88%, 55%);
}

.van-popup .van-popup--bottom{
   background-color: aliceblue;
}

.repayment-detail {
  font-size: 14px;
  margin-left:10px;
  margin-right:10px;
}

.summary {
  padding: 10px;
  background-color: rgb(223, 69, 69);
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-top:5px;
}

.details {
    border-radius: 8px;
    background-color: white; 
}

.summary p {
  font-size:14px;
  margin: 5px;
  color: white;
}
.summary .pay{
   font-size:18px;
}

.details .repayment-item {
  padding: 1px;
  background-color: #fff;
  border-bottom: 1px dashed #ccc;
}

.repayment-item p{
    margin: 5px 0 !important;
}

.repayment-item .money{
    float: right;
    font-size:12px;
    color:gray;
}

.repayment-detail-date {
  color: #999;
}


.loan-details {
    max-width: 600px;
    /* margin: 20px auto; */
    padding: 20px;
    background-color: aliceblue;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    font-family: 'Arial', sans-serif;
}

.formitem{
   padding: 10px;
   background-color: #fff;
   border-radius: 10px;
   height:100%;
   margin-bottom: 10%;
}


.loan-details .form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    /* border: 1px solid #eaeaea; */
    border-radius: 4px;
    border-bottom: 1px dashed #ccc;
    font-size:14px;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
}



.loan-details .form-item label {
    color: rgb(73, 73, 73);
}

.loan-details .form-item span {
    color: #666;
}


.loan-details .submit-button {
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
}

.loan-details .submit-button:hover {
    background-color: #e2b37d;
    border-color: #ccda12;
}

.form-item .hint {
    color: #e53e3e !important;
}


</style>

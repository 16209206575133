<template>
  <div>
    <div class="page" v-if="!showAuth">
      <div v-if="!showLoadingCountdown">
        <van-nav-bar title="基本信息" left-text="" />

        <van-form @submit="onSubmitForm">
          <!-- 行业类别和岗位 -->
          <van-field
            readonly
            clickable
            label="行业类别"
            v-model="selectedIndustry"
            class="industry"
            placeholder="请选择行业"
            @click="showIndustryPicker = true"
            required
          >
          </van-field>

          <!-- 公司名称 -->
          <van-field
            v-model="formData.companyName"
            label="公司名称"
            class="company"
            placeholder="请输入公司名称"
            required
          >
          </van-field>

          <!-- :value="selectedAddress" -->
          <!-- 居住地址 -->
          <van-field
            readonly
            clickable
            label="居住地址"
            class="livingAddress"
            v-model="selectedAddress"
            placeholder="请选择省市区"
            @click="showAddressPicker = true"
            required
          >
          </van-field>

          <!-- 详细地址 -->
          <van-field
            v-model="formData.addressDetail"
            class="detailAddress"
            label="详细地址"
            placeholder="请输入详细地址"
            required
          >
          </van-field>

          <!-- 常用邮箱 -->
          <van-field
            v-model="formData.email"
            label="常用邮箱"
            class="email"
            placeholder="请输入常用邮箱"
            @input="onEmailInput"
            @blur="onEmailBlur"
            required
          >
          </van-field>
          <!-- 邮箱后缀推荐列表 -->
          <!-- 邮箱后缀推荐列表 -->
          <div
            v-if="emailRecommendations.length > 0"
            class="email-recommendations"
          >
            <div
              v-for="(email, index) in emailRecommendations"
              :key="index"
              @click="selectEmail(email)"
              class="email-recommendation-item"
            >
              {{ email }}
            </div>
          </div>

          <!-- 银行卡号 -->
          <van-field
            v-model="formData.bankCardNumber"
            label="银行卡号"
            placeholder="请输入银行卡号"
            class="bankNumber"
            required
          >
          </van-field>

          <!-- 开户行 -->
          <van-field
            v-model="formData.bankName"
            label="开户行"
            class="bankName"
            placeholder="请输入开户行"
            required
          >
          </van-field>

          <!-- 人像面上传 -->
          <div class="shenfen">
            <label><span style="color: red">*</span>身份证</label>
          </div>
          <!-- 身份证上传 -->
          <div class="id-card-upload">
            &nbsp;&nbsp;&nbsp;
            <!-- 人像面上传区域 -->
            <div class="upload-area">
              <van-icon name="photo-o" class="upload-icon" />
              <div>人像面</div>
              <van-uploader
                v-model="formData.idCardFront"
                :max-count="1"
                @oversize="onOversize"
                name="id_card_front"
                class="front"
                multiple="false"
                show-upload="true"
                :before-read="beforeRead"
                upload-text="上传人像面"
                accept="image/*"
                :after-read="handleFrontUpload"
                required
              />
            </div>
            &nbsp;&nbsp;
            <!-- 国徽面上传区域 -->
            <div class="upload-area">
              <van-icon name="photo-o" class="upload-icon" />
              <div>国徽面</div>
              <van-uploader
                v-model="formData.idCardBack"
                name="id_card_back"
                :max-count="1"
                @oversize="onOversize"
                class="back"
                :before-read="beforeRead"
                multiple="false"
                show-upload="true"
                accept="image/*"
                upload-text="上传国徽面"
                :after-read="handleBackUpload"
                required
              />
            </div>
            &nbsp;&nbsp;&nbsp;
          </div>

          <van-nav-bar title="紧急联系人信息" left-text="" />
          <van-form>
            <!-- 紧急联系人 -->
            <div
              v-for="(contact, index) in formData.contacts"
              :key="index"
              class="contact-item"
            >
              <van-field
                readonly
                clickable
                v-model="contact.relation"
                label="关系"
                placeholder="请选择关系"
                @click="openContract(index)"
                required
              />
              <van-field
                v-model="contact.name"
                label="姓名"
                placeholder="请输入姓名"
                required
              />
              <van-field
                v-model="contact.phone"
                label="手机号"
                placeholder="请输入手机号"
                required
              />
              <van-icon
                name="cross"
                class="delete-contact"
                @click="deleteContact(index)"
              />
            </div>
            <view
              style="
                display: flex;
                justify-content: space-around;
                margin-top: 5px;
              "
            >
              <van-button type="primary" plain round @click="addContact"
                >继续添加紧急联系人</van-button
              >
            </view>
            <br />
            <!-- 阅读并同意 -->
            <van-checkbox class="benren" v-model="formData.agreed"
              >本人已经阅读并同意签署<span
                class="qianshu"
                style="color: red"
                @click="change"
                >《个人信息查询及使用授权书》</span
              ></van-checkbox
            >
          </van-form>
          <!-- 提交按钮 -->
          <van-button
            type="danger"
            block
            round
            native-type="submit"
            class="submit-button"
          >
            <van-loading v-if="loading" type="spinner" size="20px" />下一步
          </van-button>
        </van-form>

        <!-- 底部弹出的选择器 -->
        <van-popup v-model:show="showIndustryPicker" round position="bottom">
          <!-- 行业和岗位选择器的实现略 -->
          <van-picker
            show-toolbar
            :columns="industryColumns"
            @cancel="showIndustryPicker = false"
            @confirm="onConfirmIndustry"
          />
        </van-popup>

        <van-popup v-model:show="showAddressPicker" round position="bottom">
          <!-- 地址选择器的实现略 -->
          <van-area
            :area-list="areaList"
            @cancel="showAddressPicker = false"
            @confirm="onConfirmAddress"
          />
        </van-popup>

        <van-popup v-model:show="showRelationPicker" round position="bottom">
          <!-- 关系选择器的实现略 -->
          <van-picker
            :columns="relations"
            @cancel="showRelationPicker = false"
            @confirm="onConfirmRelation"
          />
        </van-popup>
        <CustomAlert
          v-if="showAlert"
          :message="alertMessage"
          :type="alertType"
        />
      </div>
      <!-- 倒计时和结果展示 -->
      <div v-if="showLoadingCountdown">
        <LoadingCountdown
          :bankCardNumber="formData.bankCardNumber"
          :userid="userid"
          :email="formData.email"
        />
      </div>
    </div>
    <div v-if="showAuth">
      <van-icon name="cross" class="delete-contact" @click="close()" />
      <div id="show1"><span id="show"></span></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Toast } from "vant";
import LoadingCountdown from "./LoadingCountdown.vue";
import CustomAlert from "./CustomAlert.vue";
import axios from "@/axios";
import { areaList } from "@vant/area-data";

const formData = ref({
  companyName: "",
  addressDetail: "",
  email: "",
  bankCardNumber: "",
  bankName: "",
  idCardFront: [], // 存储人像面图片
  idCardBack: [], // 存储国徽面图片
  contacts: [{ name: "", phone: "", relation: "" }],
  agreed: false,
});

const showIndustryPicker = ref(false);
const showAddressPicker = ref(false);
const showRelationPicker = ref(false);
const showAuth = ref(false);
const userid = ref("");

const selectedIndustry = ref("");
const selectedAddress = ref("");
const selectedRelation = ref("");

const emailSuffixes = [
  "@qq.com",
  "@163.com",
  "@gmail.com",
  "@yahoo.com",
  "@outlook.com",
];

const emailRecommendations = ref([]);

const showAlert = ref(false);
const alertMessage = ref("");
const alertType = ref("");

const front = ref();
const back = ref();

//倒计时
const loading = ref(false);
const countdown = ref(3);
const showResult = ref(false);
const showLoadingCountdown = ref(false);

// 模拟的行业和岗位数据
const industryAndPositions = [];

const handleFrontUpload = (file) => {
  front.value = file.file;
};

const handleBackUpload = (file) => {
  back.value = file.file;
};

const industryColumns = ref(industryAndPositions);

const showCustomAlert = (message, type = "info") => {
  alertMessage.value = message;
  alertType.value = type;
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};

const index = ref("");

const openContract = (index1) => {
  showRelationPicker.value = true;
  index.value = index1;
};

const show = ref();

const change = () => {
  showAuth.value = true;
  setTimeout(() => {
    document.getElementById("show").innerHTML = show.value;
  }, 500);
};

const close = () => {
  showAuth.value = false;
};

const fetchTableData = async () => {
  try {
    const response = await axios.get("/industries");
    if (response.status == 200) {
      if (response.data != null) {
        for (let i = 0; i < response.data.length; i++) {
          var industry = response.data[i].industry;
          var id = response.data[i].id;
          var data1 = {
            text: industry,
            value: i,
            children: [],
          };
          const response1 = await axios.get(`/by-industry/${id}`);
          if (response1.data != null) {
            for (let j = 0; j < response1.data.length; j++) {
              data1.children.push({
                text: response1.data[j].position,
                value: i + j,
              });
            }
          }
          industryAndPositions.push(data1);
        }
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
  }
  try {
    const response = await axios.get("/task-relationships");
    if (response.status == 200) {
      if (response.data != null) {
        for (let i = 0; i < response.data.length; i++) {
          var relationship = response.data[i].relationship;
          var data2 = {
            value: response.data[i].importance,
            text: relationship,
          };
          relations.push(data2);
        }
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
  }

  try {
    const response = await axios.get("/parameter");
    if (response.status == 200) {
      if (response.data != null) {
        show.value = response.data.content;
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    console.log(error);
    showCustomAlert(error.response.data.error || "获取数据失败", "error");
    console.error("获取数据失败:", error);
  }
};

onMounted(fetchTableData);

function onEmailInput(value) {
  // 确保value总是被当作字符串处理
  const inputValue = typeof value === "string" ? value : String(value);

  const atIndex = inputValue.indexOf("@");
  if (atIndex !== -1) {
    const enteredDomain = inputValue.substring(atIndex);
    emailRecommendations.value = emailSuffixes.filter((suffix) =>
      suffix.startsWith(enteredDomain)
    );
  } else {
    emailRecommendations.value = emailSuffixes;
  }
}

function onEmailBlur() {
  const inputValue =
    typeof formData.value.email === "string"
      ? formData.value.email
      : String(formData.value.email);

  const atIndex = inputValue.indexOf("@");

  if (formData.value.email == "" || atIndex !== -1) {
    emailRecommendations.value = [];
  }
}

function selectEmail(email) {
  formData.value.email = formData.value.email + email;
  emailRecommendations.value = []; // 选择后清空推荐列表
}

// 模拟的紧急联系人关系数据
const relations = [];

function onConfirmIndustry(value) {
  if (value.selectedOptions[1] != undefined) {
    selectedIndustry.value = `${value.selectedOptions[0].text} - ${value.selectedOptions[1].text}`;
  } else {
    selectedIndustry.value = `${value.selectedOptions[0].text}`;
  }
  showIndustryPicker.value = false;
}

function onConfirmAddress(value) {
  selectedAddress.value = `${value.selectedOptions[0].text}-${value.selectedOptions[1].text}-${value.selectedOptions[2].text}`;
  showAddressPicker.value = false;
}

function onConfirmRelation(value) {
  selectedRelation.value = value;
  formData.value.contacts[index.value].relation = value.selectedOptions[0].text;
  showRelationPicker.value = false;
}

function beforeRead(file, detail) {
  console.log(file, detail);
  // 这里可以添加文件读取前的逻辑，例如检查文件类型
  return true;
}

// 图片压缩函数
const compressImage = (file, maxWidth = 1000) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // 创建 canvas 进行压缩
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // 将 canvas 内容转换为 Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("图片压缩失败"));
          }
        }, file.type || "image/jpeg");
      };
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};


function onOversize(files) {
  console.log(files);
  Toast("文件大小超出限制");
}

async function onSubmitForm() {
  // 判空
  const fields = [
    { value: selectedIndustry.value, name: "行业职位", class: "industry" },
    { value: formData.value.companyName, name: "公司名称", class: "company" },
    { value: selectedAddress.value, name: "居住地址", class: "livingAddress" },
    {
      value: formData.value.addressDetail,
      name: "详细地址",
      class: "detailAddress",
    },
    { value: formData.value.email, name: "邮箱", class: "email" },
    {
      value: formData.value.bankCardNumber,
      name: "银行卡号",
      class: "bankNumber",
    },
    { value: formData.value.bankName, name: "银行名称", class: "bankName" },
    { value: front.value, name: "身份证正面照", class: "front" },
    { value: back.value, name: "身份证反面照", class: "back" },
  ];

  for (const field of fields) {
    if (
      !field.value ||
      (typeof field.value === "string" && !field.value.trim())
    ) {
      showCustomAlert(`${field.name}不能为空`, "warning");
      const container = document.querySelector("." + field.class);
      if (container) {
        container.classList.add("highlight");
        // 获取元素相对于视窗的位置
        const bounding = container.getBoundingClientRect();
        // 计算元素的绝对位置
        const absoluteTop = window.pageYOffset + bounding.top;
        // 滚动到元素位置，可以调整偏移量以更好地定位
        window.scrollTo({ top: absoluteTop - 100, behavior: "smooth" });

        setTimeout(() => {
          container.classList.remove("highlight");
        }, 2000);
      }
      return `${field.name}不能为空`;
    }
  }

  if (!formData.value.agreed) {
    showCustomAlert(`请先同意条款`, "warning");
    const checkboxContainer = document.querySelector(".benren");
    if (checkboxContainer) {
      checkboxContainer.classList.add("highlight");

      // 获取元素相对于视窗的位置
      const bounding = checkboxContainer.getBoundingClientRect();
      // 计算元素的绝对位置
      const absoluteTop = window.pageYOffset + bounding.top;
      // 滚动到元素位置，可以调整偏移量以更好地定位
      window.scrollTo({ top: absoluteTop - 100, behavior: "smooth" });

      setTimeout(() => {
        checkboxContainer.classList.remove("highlight");
      }, 2000);
    }
    return;
  }

  for (let i = 0; i < formData.value.contacts.length; i++) {
    if (!formData.value.contacts[i].relation.trim()) {
      showCustomAlert(`请选择联系人关系`, "warning");
      return;
    }
    if (!formData.value.contacts[i].name.trim()) {
      showCustomAlert(`联系人姓名不能为空`, "warning");
      return;
    }
    if (!formData.value.contacts[i].phone.trim()) {
      showCustomAlert(`联系人电话不能为空`, "warning");
      return;
    }
  }

  loading.value = true;

  //先提交
  const data = new FormData();

  data.append("industry_position", selectedIndustry.value);
  data.append("company", formData.value.companyName);
  data.append("residential_address", selectedAddress.value);
  data.append("detailed_address", formData.value.addressDetail);
  data.append("email", formData.value.email);
  data.append("bank_account_number", formData.value.bankCardNumber);
  data.append("bank_name", formData.value.bankName);

  const compressedImage1 = await compressImage(front.value);
  const compressedImage2 = await compressImage( back.value);
  data.append("id_card_front", compressedImage1);
  data.append("id_card_back", compressedImage2);

  try {
    const response = await axios.post("/personal-details", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status == 200) {
      //提交联系人信息，formData.value.contacts
      var id = response.data.user;
      userid.value = id;
      var contracts = [];
      for (let i = 0; i < formData.value.contacts.length; i++) {
        var contract = {
          relationship: formData.value.contacts[i].relation,
          name: formData.value.contacts[i].name,
          phone_number: formData.value.contacts[i].phone,
        };
        contracts.push(contract);
      }
      var data1 = {
        userid: id,
        contracts: contracts,
      };
      //上传
      const response1 = await axios.post("/emergency-contacts", data1);
      if (response1.status == 200) {
        showCustomAlert(response1.data.message, "success");
        let counter = 3;
        const intervalId = setInterval(() => {
          countdown.value = counter--;
          if (counter < 0) {
            clearInterval(intervalId);
            loading.value = false;
            showResult.value = true;
            showLoadingCountdown.value = true;
          }
        }, 1000);
        // loading.value = false;
        // showResult.value = true;
        // showLoadingCountdown.value = true;
      } else {
        showCustomAlert(response1.data.error, "error");
      }
    } else {
      showCustomAlert(response.data.error, "error");
    }
  } catch (error) {
    console.error(error);
    showCustomAlert(error.response.data.error || "提交失败", "error");
  }
}

function addContact() {
  formData.value.contacts.push({ name: "", phone: "", relation: "" });
}

function deleteContact(index) {
  formData.value.contacts.splice(index, 1);
}
</script>

<style scoped>
.shenfen {
  font-size: 14px;
  color: #323233;
  padding-left: 5%;
  padding-top: 3%;
  padding-bottom: 2%;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
}
.id-card-upload > div {
  flex: 0.2;
}

.id-card-upload {
  display: flex;
  justify-content: space-between;
}

.contact-item {
  position: relative;
  margin-bottom: 10px;
}

.delete-contact {
  position: absolute;
  right: 0;
  top: 0;
  color: red;
  font-size: 20px;
}
van-checkbox {
  font-size: 13px !important;
}

.page {
  padding-bottom: 60px; /* 根据按钮高度调整 */
  position: relative;
}

.submit-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px; /* 按钮高度 */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* 可选，为按钮添加阴影 */
}

.benren {
  color: #666;
  font-size: 13px;
  font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif;
}
.required {
  color: red;
}

/* 图片上传区域的样式 */
.upload-area {
  border: 1px dashed rgb(179, 171, 171); /* 红色虚线边框 */
  border-radius: 10px; /* 轻微的圆角效果 */
  padding: 20px; /* 内边距，根据需要调整 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* 与下方内容的间距 */
  font-size: 12px;
}

#show1 {
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB",
    "Microsoft Yahei", sans-serif;
  padding-left: 12px;
  padding-right: 13px;
  padding-top: 10px;
  background: rgb(226, 237, 241);
  text-align: justify;
}

/* 上传图标的样式 */
.upload-icon {
  font-size: 24px; /* 图标大小，根据需要调整 */
  color: red; /* 图标颜色 */
  margin-bottom: 8px; /* 图标与文字的间距 */
}

/* 邮箱后缀推荐列表的样式 */
.email-recommendations {
  border: 1px solid #ccc;
  border-top: none; /* 如果输入框已有边框，可以去掉顶部边框以融合 */
  max-height: 200px; /* 可以根据需要调整 */
  overflow-y: auto; /* 超过最大高度时允许滚动 */
  background-color: white;
}

.email-recommendation-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.email-recommendation-item:hover {
  background-color: #f0f0f0; /* 鼠标悬停时的背景色 */
}

.result {
  text-align: center;
  margin-top: 20px;
}

.circle {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin: 20px auto;
  font-size: 24px;
}

.congratulations,
.rate {
  margin-top: 10px;
}

.loan-button {
  margin-top: 20px;
}

.highlight {
  animation: highlightAnimation 2s ease-in-out;
}

@keyframes highlightAnimation {
  0% {
    background-color: rgb(215, 243, 59);
  }
  100% {
    background-color: transparent;
  }
}
</style>
